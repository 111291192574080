
import axios from "axios";
import rrController from "../../controllers/researchRequests/index";
import { isEqual, keys } from "underscore";
import awsExports from "../../aws_exports";
import { defineStore } from 'pinia';
import { useAppSetupStore } from "@/store/appSetup";
import { useAuthenticationStore } from "@/store/authentication";

const RESEARCH_REQUEST = {
  id: null,
  requestDate: null,
  requestStatus: "Unsubmitted",
  submitterName: null,
  submitterEmail: null,
  submitterTitle: null,
  submitterOrg: null,
  isSubmitterVrdeUser: null,
  submitterPhone: null,

  piName: null,
  piTitle: null,
  piEmail: null,
  piGuid: null,
  affiliatedOrg: null,
  isPiVrdeUser: null,
  hasIrb: null, // Deprecated
  irbSubmissionStatus: null, // Deprecated
  otherIrbSubmissionStatus: null, // Deprecated
  irbRequirements: null,
  irbInformation: null,
  otherCollaborators: null, // Deprecated
  collaborators: [],
  isRiceAffiliated: null,
  isWorkingWithRice: null,
  riceResearcherDepartment: null,

  projectName: null,
  projectDescription: null,
  requestedData: [],
  additionalData: null,
  dataPlan: null,
  requiredResources: null,
  proposedDeliverable: null,
  projectCompletionDate: null,
  additionalComments: null,
  rejectionReason: "",
  documentation: [],
}

export const useResearchRequestStore = defineStore("researchRequest", {
  state: () => ({
    all: [],
    request: {},
    originalRequest: null,
    requestFormatted: {
      id: "ID",
      requestDate: null,
      requestStatus: "Unsubmitted",
      submitterName: "Submitter Name",
      submitterEmail: "Submitter Email",
      submitterTitle: "Submitter Title",
      submitterOrg: "Submitter Organization",
      isSubmitterVrdeUser:
        "Have you, the submitter, ever used the UDP's VRDE system before?",
      submitterPhone: "Submitter Phone",

      piName: "Principal Investigator Name",
      piTitle: "Principal Investigator Title",
      piEmail: "Principal Investigator Email",
      piGuid: null,
      affiliatedOrg: "Affiliated Organization",
      isPiVrdeUser:
        "Has the Principal Investigator used the UDP's VRDE system before?",
      irbRequirements: "IRB Requirements",
      irbInformation: "IRB Information",
      otherCollaborators:
        "Other Collaborators who will need access to UDP's VRDE system", // Deprecated
      collaborators:
        "Other Collaborators who will need access to UDP's VRDE system",
      isRiceAffiliated:
        "Is the Principal Investigator currently a member of Rice University's staff, faculty, or student body?",
      isWorkingWithRice:
        "Is the Principal Investigator currently working with a researcher or department at Rice University?",
      riceResearcherDepartment: "Rice researchers or departments",

      projectName: "Project Name",
      projectDescription: "Project Description",
      requestedData: "Data you would like to request for the proposed project",
      additionalData: "Additional data needed for the proposed project",
      dataPlan: "What do you plan to do with the data?",
      requiredResources:
        "What resources or tools do you need for the proposed project?",
      proposedDeliverable:
        "What is the proposed deliverable upon completion of your project?",
      projectCompletionDate:
        "What is the general timeline for completion of your project?",
      additionalComments: "Additional comments or information",
      rejectionReason: "",
      documentation: "Supporting Documentation",
    },
  }),
  getters: {
    requestDifferences() {
      if (!this.requestObjectsMatch) {
        let diff = {};
        keys(this.originalRequest).forEach((key) => {
          // If the value for the given key differs, store the key/value in the diff object
          if (this.originalRequest[key] !== this.request[key]) {
            diff[this.requestFormatted[key]] = this.request[key];
          }
        });
        return diff;
      } else {
        return null;
      }
    },
    requestObjectsMatch() {
      return isEqual(this.request, this.originalRequest);
    },
    documentationBucket() {
      const appSetupStore = useAppSetupStore()

      return appSetupStore.appEnv === "development"
        ? awsExports.dev.buckets.userDocumentation
        : awsExports.prod.buckets.userDocumentation;
    },
  },
  actions: {
    prepopulateSubmitterInfo() {
      const authenticationStore = useAuthenticationStore()
      // Commit attribute changes to submitter info from signed in user attributes
      this.request.submitterName = authenticationStore.fullName
      this.request.submitterEmail = authenticationStore.userAttributes.email
      this.request.submitterTitle = authenticationStore.userAttributes.title
      this.request.submitterOrg = authenticationStore.userAttributes.organization
    },
    async getResearchRequestById(data) {
      const appSetupStore = useAppSetupStore();
      //* @refactor: move direct api calls to controllers?
      // Make request for array of datasets
      let response = await axios.get(
        appSetupStore.apiUrl +
          "admin/submit/researchRequest/" +
          data.finalizeId
      );

      // Validate that the piGuid returned matches the finalizeGuid
      if (response.data.piGuid === data.finalizeGuid) {
        // Populate researchRequest object
        this.resetRequest();
        this.setRequest(response.data);
      } else {
        throw new Error();
      }
    },
    // Calls controller to get Research Request by provided ID
    async loadResearchRequestDetail(id) {
      try {
        let response = await rrController.byId(id);
        this.resetRequest();
        this.setRequest(response.data);
      } catch (error) {
        console.error(error);
        // commit("SET_ERROR", true);
      }
    },
    async listResearchRequests() {
      let requests = await rrController.all();
      this.all = requests;
      // commit('SET_ERROR', true)
      // Might need to dispatch for updated dataset based on CORB issue?
    },
    async submitResearchRequest(researchRequest) {
      await rrController.create(researchRequest);
      this.resetRequest();
      // commit('SET_ERROR', true)
      // Might need to dispatch for updated dataset based on CORB issue?
    },
    async dataLibrarianApproveResearchRequest(acceptData) {
      let response = await rrController.dataLibrarianApprove(
        this.request.id,
        acceptData
      );
      this.resetRequest();
      this.setRequest(response.data.Attributes);

    },
    async dataLibrarianRejectResearchRequest(rejectData) {
      let response = await rrController.dataLibrarianReject(
        this.request.id,
        rejectData
      );
      this.resetRequest();
      this.setRequest(response.data.Attributes);
      // commit('SET_ERROR', true)
      // Might need to dispatch for updated dataset based on CORB issue?
    },
    // Accept and Reject for PIs
    acceptResearchRequest(acceptData) {
      return rrController.accept(acceptData);
    },
    rejectResearchRequest(rejectData) {
      return rrController.reject(rejectData);
    },
    // UCMT finalize request
    async finalizeResearchRequest(finalizeData) {
      let response = await rrController.finalize(
        this.request.id,
        finalizeData
      );
      this.resetRequest();
      this.setRequest(response.data.Attributes);
      // commit('SET_ERROR', true)
      // Might need to dispatch for updated dataset based on CORB issue?
    },
    async notifyResearchRequest(notifyData) {
      let response = await rrController.notify(this.request.id, notifyData);
      this.resetRequest();
      this.setRequest(response.data.Attributes);
      // commit('SET_ERROR', true)
      // Might need to dispatch for updated dataset based on CORB issue?
    },
    async deleteResearchRequest(id) {
      let response = await rrController.delete(id);
      console.log(response);
      // commit('SET_ERROR', true)
      // Might need to dispatch for updated dataset based on CORB issue?
    },
    async addOversightCommitteeComment(commentData) {
      let response = await rrController.comment(this.request.id, commentData);
      this.resetRequest();
      this.setRequest(response.data.Attributes);
    },
    // Mutations
    setRequest(data) {
      data.documentation = data.documentation ? data.documentation : [];
      this.request = Object.assign(RESEARCH_REQUEST, JSON.parse(JSON.stringify(data)));
      this.originalRequest = Object.assign(RESEARCH_REQUEST, JSON.parse(JSON.stringify(data)));
    },
    resetRequest() {
      this.request = Object.assign({}, RESEARCH_REQUEST)
    }
  },
});
