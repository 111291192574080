import './assets/bulma/udp-bulma.sass'
import '@fontsource/lato'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'
import './global-styles.css'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import Toasted from 'vue-toasted'
import { createPinia, PiniaVuePlugin } from 'pinia'

/* Configure aws-amplify global */
import awsExports from './aws_exports'
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'

// Way to get top-level async/await
(async () => {
  // Configure Amplify
  const env = (process.env.VUE_APP_ENV.trim() === 'development') ? awsExports.dev : awsExports.prod
  Amplify.configure(env)

  Vue.config.ignoredElements = ['trix-editor']
  Vue.config.productionTip = false

  //* @refactor: Move away from a global toast library since it's only used in one place currently
  Vue.use(Toasted, {
    iconPack: 'fontawesome'
  })
  Vue.use(PiniaVuePlugin)
  const pinia = createPinia()

  Vue.use(VueRouter)

  try {
    // Get the current user if still authenticated on this device
    Window.currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true })

    // User is signed in!
    Window.isSignedIn = true
  } catch (err) {
    // User is NOT signed in
    Window.isSignedIn = false
  } finally {
    // Initialize Vue now that we know if use has an existing session or not
    new Vue({
      pinia,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
  })();
