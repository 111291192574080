import Hashids from 'hashids'
import { format, parseISO, parse, isValid } from 'date-fns'
import emailValidator from 'email-validator'
import { S3Client, ListObjectsV2Command, GetObjectCommand, PutObjectCommand, DeleteObjectCommand, CopyObjectCommand, HeadObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { Auth }  from '@aws-amplify/auth'
import { useAuthenticationStore } from '@/store/authentication'
import { useAppSetupStore } from '@/store/appSetup'

const region = process.env.VUE_APP_ENV.trim() === 'development' ? 'us-west-2' : 'us-east-1'



/* AWS helper functions */
const _formatS3Key = function (Prefix, ObjectKey) {
  let Key
  if (Prefix) Key = encodeURIComponent(Prefix.trim()) + '/' + encodeURIComponent(ObjectKey.trim())
  else Key = encodeURIComponent(ObjectKey.trim())
  return Key
}
const _returnS3Object = async function () {
  // Get credential
  let credentials = await Auth.currentCredentials()

  // Return S3 Cleint
  return new S3Client({
    region,
    credentials
  })

  /*
  Saw this online, but essentuialCredentials() appears to be identical to currentCredentials()

  let credentialsresult = await Auth.essentialCredentials(credentials);
  console.log(credentialsresult)
  */
}

const _getS3PresignedURL = async function (Key, Bucket) {
  // Get S3 client
  let client = await _returnS3Object()

  // Create a command to put the object in the S3 bucket.
  const command = new GetObjectCommand({
    Bucket,
    Key
  })
  // Create and return the presigned URL
  return await getSignedUrl(client, command, {
    expiresIn: 360,
  });
}

const _listS3Objects = async function (Bucket, Prefix) {
  // Array to return
  let objects = []

  // Get S3 client
  let client = await _returnS3Object()

  return await client.send(new ListObjectsV2Command({
    Bucket,
    Prefix
  }))


  // FIGURE OUT WHERE BELOW CODE WAS CALLED AND FIX REFERENCES
  /*

    let objects = []

    this.returnS3Object().then(s3 => {
      // List objects in S3 bucket with ID (folder) prefix; ignore size 0 (its the 'folder')
      s3.listObjectsV2({
        Bucket: bucket,
        Prefix: prefix
      }).promise().then(data => {
        // Loop through data.Contents
        data.Contents.forEach(obj => {
          if (obj.Size > 0) {
            // Push object to array
            objects.push({
              'ETag': obj.ETag,
              'key': obj.Key,
              'size': obj.Size
            })
          }
        })
        resolve(objects)
      })
    })
  })
  */
}
const _uploadS3Object = async function (Bucket, ObjectKey, Prefix, Metadata, Body) {
  const Key = _formatS3Key(Prefix, ObjectKey)

  // Get S3 client
  let client = await _returnS3Object()

  return await client.send(new PutObjectCommand({
    Bucket,
    Key,
    Body,
    Metadata
  }))
}
const _headS3Object = async function (Bucket, Key) {
  // If you ever run into issues with this not pulling S3 Metadata, make sure CORS policy on S3 bucket allows them

  // Get S3 client
  let client = await _returnS3Object()

  return await client.send(new HeadObjectCommand({
    Bucket,
    Key
  }))
}

const _deleteS3Object = async function (Key, Bucket) {
  // Get S3 client
  let client = await _returnS3Object()

  return await client.send(new DeleteObjectCommand({
    Bucket,
    Key
  }))
}

const _copyOverwriteS3ObjectMetadata = async function (Key, Bucket, Metadata) {
  // Get S3 client
  let client = await _returnS3Object()
  const CopySource = _formatS3Key(Bucket, Key)
  const MetadataDirective = 'REPLACE'

  return await client.send(new CopyObjectCommand({
    Bucket,
    Key,
    CopySource,
    Metadata,
    MetadataDirective
  }))
}

/* Asset Manager functions */

export async function getUDPAssets() {
  const appSetupStore = useAppSetupStore()

  // Assets object to be returned
  let assets = {
    Global: []
  }
  const Bucket = appSetupStore.appEnv === 'development' ? 'udp-assets-dev' : 'udp-assets'

  const objects = await _listS3Objects(Bucket)

  objects.Contents.forEach(obj => {
    let splitParts = obj.Key.split('/')

    if (splitParts.length > 1) {
      // If key isn't present on assets object, add it
      if (!assets[splitParts[0]]) assets[splitParts[0]] = []
      // Then append objects to the array
      assets[splitParts[0]].push(obj)
    } else assets.Global.push(obj)
  })

  return assets
}
export async function uploadUDPAsset (uploadObj, datasetID) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-assets-dev' : 'udp-assets'
  const Key = uploadObj.uploadFile.name
  const Body = uploadObj.uploadFile

  if(Bucket === 'udp-assets-dev'){
    datasetID = (datasetID.length > 0) ? 'dev/' + datasetID : 'dev'
  }

  const Metadata = {
    'original-filename': uploadObj.uploadFile.name.trim(),
    'cache-control': 'no-cache,no-store'
  }

  return await _uploadS3Object(Bucket, Key, datasetID, Metadata, Body)
}
export async function deleteUDPAsset (Key) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-assets-dev' : 'udp-assets'

  return await _deleteS3Object(Key, Bucket)
}

/* Research Request documentation-related functions */
export async function getRRDocumentation (rrID) {
  // Assets object to be returned
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-user-documentation-dev' : 'udp-user-documentation'

  const response = await _listS3Objects(Bucket, rrID)

  if (response.KeyCount === 0) return []
  
  // Loop through docObj.documentation, get all Metadata keys for all the files, then return it
  let promises = []
  response.Contents.forEach((obj) => {
    // Make request to get metadata for item and add to promises array
    promises.push(_headS3Object(Bucket, obj.Key))
  })
  // Handle promises array when all are complete
  let headObjs = await Promise.all(promises)

  // Loop through all promise responses and add description and upload datetime
  headObjs.forEach((obj, i) => {
    // These should remain in the order added, but double check ETags match to be sure
    if (obj.ETag === response.Contents[i].ETag) {
      // Set description and uploadDatetime
      response.Contents[i].description = obj.Metadata['udp-description'] ? obj.Metadata['udp-description'] : null
      response.Contents[i].uploadDatetime = obj.Metadata['udp-upload-datetime'] ? parseInt(obj.Metadata['udp-upload-datetime'], 10) : null
    } else console.log('How did this happen?') // Code should never reach here
  })
  
  return response.Contents
}
export async function openRRDocumentation(Key) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-user-documentation-dev' : 'udp-user-documentation'

  return await _getS3PresignedURL(Key, Bucket)
}

export async function uploadRRDocumentation (uploadObj, rrID) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-user-documentation-dev' : 'udp-user-documentation'
  const Key = uploadObj.filename
  const Body = uploadObj.file
  const Metadata = {
    'original-filename': uploadObj.filename.trim(),
    'udp-description': uploadObj.description.trim(),
    'udp-upload-datetime': Date.now().toString()
  }

  return await _uploadS3Object(Bucket, Key, rrID, Metadata, Body)
}
export async function updateRRDocumentationMetadata (Key, Metadata) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-user-documentation-dev' : 'udp-user-documentation'

  return await _copyOverwriteS3ObjectMetadata(Key, Bucket, Metadata)
}
export async function deleteRRDocumentation(Key) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udp-user-documentation-dev' : 'udp-user-documentation'

  return await _deleteS3Object(Key, Bucket)
}

/* Dataset download package related functions */
export async function getDownloadPackages (datasetID) {
  // Assets object to be returned
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udpdownloads-dev' : 'udpdownloads'

  const response = await _listS3Objects(Bucket, datasetID)

  if (response.KeyCount === 0) return []

  // Loop through docObj.documentation, get all Metadata keys for all the files, then return it
  let promises = []
  response.Contents.forEach((obj) => {
    // Make request to get metadata for item and add to promises array
    promises.push(_headS3Object(Bucket, obj.Key))
  })
  // Handle promises array when all are complete
  let headObjs = await Promise.all(promises)

  // Loop through all promise responses and add description and upload datetime
  headObjs.forEach((obj, i) => {
    // These should remain in the order added, but double check ETags match to be sure
    if (obj.ETag === response.Contents[i].ETag) {
      // Set description and uploadDatetime
      response.Contents[i].displayName = obj.Metadata['udp-display-name'] ? obj.Metadata['udp-display-name'] : null
      response.Contents[i].fileType = obj.Metadata['udp-file-type'] ? obj.Metadata['udp-file-type'] : null
      response.Contents[i].uploadDatetime = obj.Metadata['udp-upload-datetime'] ? parseInt(obj.Metadata['udp-upload-datetime'], 10) : null
    } else console.log('How did this happen?') // Code should never reach here
  })

  return response.Contents

}
export async function openDownloadPackage(Key) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udpdownloads-dev' : 'udpdownloads'

  return await _getS3PresignedURL(Key, Bucket)
}
export async function uploadDownloadPackage (uploadObj, datasetID) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udpdownloads-dev' : 'udpdownloads'
  const Key = uploadObj.Key
  const Body = uploadObj.Body
  const Metadata = {
    'udp-display-name': uploadObj.displayName.trim(),
    'udp-file-type': uploadObj.fileType.trim(),
    'udp-upload-datetime': Date.now().toString()
  }

  return await _uploadS3Object(Bucket, Key, datasetID, Metadata, Body)
}
export async function updateDownloadPackageMetadata (Key, Metadata) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udpdownloads-dev' : 'udpdownloads'

  return await _copyOverwriteS3ObjectMetadata(Key, Bucket, Metadata)
}
export async function deleteDownloadPackage(Key) {
  const Bucket = useAppSetupStore().appEnv === 'development' ? 'udpdownloads-dev' : 'udpdownloads'

  return await _deleteS3Object(Key, Bucket)
}

/* Formatting related functions */
export function formatFileSize (size) {
  if (size) {
    if (size < 1024) {
      return size + ' bytes'
    } else if (size >= 1024 && size <= 1048576) {
      return (size / 1024.0).toFixed(2) + ' KB'
    } else if (size >= 1048576 && size <= 1073741824) {
      return (size / 1048576.0).toFixed(2) + ' MB'
    } else if (size > 1073741824) {
      return (size / 1073741824.0).toFixed(2) + ' GB'
    }
  } else {
    return null
  }
}
export function htmlLineBreakFormat (val) {
  if (val) {
    let split = val.split(/\r?\n/g)
    let htmlFullDesc = ''
    split.forEach(part => (htmlFullDesc += part + '<br>'))
    return htmlFullDesc.slice(0, -4)
  } else return null
}
export function htmlArrayFormat (val, optionalKey) {
  if (Array.isArray(val)) {
    if (optionalKey || Number.isInteger(optionalKey)) val = val.map(v => { return v[optionalKey] })
    let htmlFullDesc = '<ul>'
    val.forEach(v => (htmlFullDesc += '<li>' + v + '</li>'))
    htmlFullDesc += '</ul>'
    return htmlFullDesc
  } else return null
}

/* ID related functions */
export function _shuffleString (str) {
  let a = str.split('')
  let n = a.length

  for (let i = n - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let tmp = a[i]
    a[i] = a[j]
    a[j] = tmp
  }
  return a.join('')
}
export function generateHash (toHash, salt) {
  // Create hash object from HashIds using salt and length
  let alpha = _shuffleString('abcdefghijklmnopqrstuvwxyz0123456789')
  let hash = new Hashids(salt, 12, alpha)
  return hash.encode(toHash)
}

/* Validation related functions */
export function validateDate (val) {
  if (val === null || val === '') return true
  else return isValid(parseISO(val)) || isValid(parse(val, 'P', new Date()))
}
export function validateEmail (val) {
  if (val === null || val === '') return true
  else return emailValidator.validate(val)
}
export function validateField (value) {
  if (value === null || value === '' || value === undefined) return false
  else return true
}
export function canAccessNextPage (to, authObj) {
  const authenticationStore = useAuthenticationStore()
  
  // Start with a truthy
  let verified = true

  // If roles are present, loop through them to verify user should have access
  if (to.meta.roles) {
    to.meta.roles.forEach(role => {
      if (role === 'isSignedIn') verified = authenticationStore.isSignedIn && verified
      else verified = authObj[role] && verified
    })
  }

  return verified
}

/* Date helper functions */
export function formatDatetime (datetime) {
  if (datetime) return format(datetime, 'PPPp')
  else return null
}
export function formatShortDate (date) {
  if (typeof date === 'string') return format(parseISO(date), 'P')
  else if (typeof date === 'number') return format(date, 'P')
  else return null
}
export function  formatMonthDay (date) {
  if (date) return format(date, 'MMM dd')
  else return null
}

/* TODO: Finish refactoring these */
export default {
  openFileFromS3 (bucket, key) {
    this.returnS3Object().then(s3 => {
      // Get URL
      const url = s3.getSignedUrl('getObject', {
        'Bucket': bucket,
        'Key': key,
        'Expires': 30
      })

      // Open file in new tab
      window.open(url, '_blank')
    })
  }
}
