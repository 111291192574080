import { Auth } from '@aws-amplify/auth'
import { v4 as uuid } from 'uuid'

import { defineStore } from 'pinia'
import { useAppSetupStore } from '@/store/appSetup'

export const useAuthenticationStore = defineStore('authentication', {
  state: () => ({
    userAttributes: {
      username: null,
      email: null,
      name: null,
      family_name: null,
      email_verified: null,
      isAdmin: false,
      isDataManager: false,
      isUserManager: false,
      isVrdeUser: false,
      isUDPCoreMgmt: false,
      isDeveloper: false,
      hasDatabaseAccess: false
    },
    sessionId: uuid(),
    isSignedIn: false,
    verificationCode: null,
    signInMessage: null,
    successMessage: null,
    postAuthRoutePath: null
  }),
  getters: {
    displayName: (state) => {
      // If the name is null, return Account
      if (state.userAttributes.name === null) {
        return 'Account'
      } else {
        // Return name
        return state.userAttributes.name.split(' ')[0]
      }
    },
    fullName: (state) => {
      return state.userAttributes.name + ' ' + state.userAttributes.family_name
    }
  },
  
  actions: {
    /*
        -This is called when a user manually logs in via the Sign In modal
    */
    async authenticateUser (payload) {
      // Attempt to sign in with user
      let user = await Auth.signIn(payload.email, payload.password)

      this.setSignedInUser(user.attributes)
    },
    setSignedInUser (userAttributes) {
      const appSetupStore = useAppSetupStore()

      this.setUserAttributes(userAttributes)
        
      // User is signed in!
      this.isSignedIn = true
      // If not in Dev, then log User Login event
      if (appSetupStore.appEnv !== 'development' || process.env.VUE_APP_ENV.trim() !== 'development') {

        const appSetupStore = useAppSetupStore();
        // Dispatch logged in event

        appSetupStore.logger({
          'location': null,
          'action': 'User Login',
          'target': null
        })
      }
    },
    registerUser (signUpData) {
      return Auth.signUp(signUpData)
    },
    confirmRegistration (confirmCode) {
      return Auth.confirmSignUp(this.userAttributes.email, confirmCode, {
        forceAliasCreation: true
      })
    },
    resendConfirmationCode () {
      return Auth.resendSignUp(this.userAttributes.email)
    },
    forgotPassword (username) {
      return Auth.forgotPassword(username)
    },
    confirmForgotPassword (userData) {
      return Auth.forgotPasswordSubmit(userData.username, userData.code, userData.password)
    },
    //* @refactor: 
    // Move this to a Lambda that will update user attributes 
    // ONLY when either its a user editing their own attributes
    // (based on JWT token) OR if requesting user has permission
    // to make user edits. Also see what other Cognito actions
    // should be moved behind a Lambda/API.             
    async updateAttributes (attributes) {
      try {
        let currentUser = await Auth.currentAuthenticatedUser()

        // This only returns a static value like SUCCESS
        // so we have to get currentUserInfo again
        await Auth.updateUserAttributes(currentUser, attributes)

        let userInfo = await Auth.currentUserInfo()
        this.setUserAttributes(userInfo.attributes)
        return userInfo
      } catch (error) {
        return error
      }
    },
    verifyEmailAddressChange (code) {
      return Auth.verifyCurrentUserAttributeSubmit('email', code)
    },
    async resendEmailVerificationCode () {
      let currentUser = await Auth.currentAuthenticatedUser()
      let result = await Auth.verifyUserAttribute(currentUser, 'email')
      return result
    },
    verifyUserCode (code) {
      const appSetupStore = useAppSetupStore();
      // Set verifcation code
      this.verificationCode = code
      // Open sign in modal with success message if code validates
      appSetupStore.isSignInModalOpen = true
    },
    async deleteAccount () {
      // Attempt to get current user
      let currentUser = await Auth.currentAuthenticatedUser()

      // Get into the old Cognito API to delete the user
      currentUser.deleteUser((err, result) => {
        if (err) return err
        else return result
      })
    },
    //* @deprecated
    async currentUserCredentials () {
      try {
        // Attempt to get current user
        return await Auth.currentUserCredentials()
      } catch (error) {
        return error
      }
    },
    //* @deprecated
    async getCurrentUserInfo () {
      return await Auth.currentUserInfo()
    },
    //* @inquiry: Which function is correct for this - currentUserCredentials, currentCredentials, essentialCredentials, other?
    async returnCurrentCredentials () {
        return await Auth.currentCredentials();
    },
    async returnIdToken () {
      try {
        let currentUser = await Auth.currentAuthenticatedUser()
        return currentUser.signInUserSession.idToken.jwtToken
      } catch (error) {
        return error
      }
    },
    setUserAttributes (attributes) {
      this.userAttributes = {
        username: attributes.sub,
        email: attributes.email,
        name: attributes.name,
        family_name: attributes.family_name ? attributes.family_name : null,
        organization: attributes['custom:organization'] ? attributes['custom:organization'] : null,
        title: attributes['custom:title'] ? attributes['custom:title'] : null,
        email_verified: attributes.email_verified === true,
        hasDatabaseAccess: attributes['custom:hasDatabaseAccess'] === '1',
        isAdmin: attributes['custom:isAdmin'] === '1',
        isDataManager: attributes['custom:isDataManager'] === '1',
        isUserManager: attributes['custom:isUserManager'] === '1',
        isVrdeUser: attributes['custom:isVrdeUser'] === '1',
        isOversightCommittee: attributes['custom:isOversightCommittee'] === '1',
        isUDPCoreMgmt: attributes['custom:isUDPCoreMgmt'] === '1',
        isDeveloper: attributes['custom:isDeveloper'] === '1'
      }
    },
    async signOut () {
      await Auth.signOut();
      this.clearUserAttributes()
      this.isSignedIn = false
    },

    // Mutations
    clearUserAttributes() {
      this.userAttributes = {
        username: null,
        email: null,
        name: null,
        family_name: null,
        organization: null,
        title: null,
        email_verified: null,
        isAdmin: false,
        isDataManager: false,
        isUserManager: false,
        isVrdeUser: false,
        isOversightCommittee: false,
        isUDPCoreMgmt: false,
        isDeveloper: false,
        hasDatabaseAccess: false
      }
    },
    //* @fix required: should be removed/changed for pinia
    //* @fixed: removed all implementations for verifyEmail and setEmail
    // verifyEmail (state) {
    //   Vue.set(state.userAttributes, 'email_verified', true)
    // },
    // setEmail (state, email) {
    //   Vue.set(state.userAttributes, 'email', email)
    // },
  }
})