import axios from 'axios'
import { useAppSetupStore } from '@/store/appSetup'

export default {
  all () {
    const appSetupStore = useAppSetupStore()
    return axios.get(appSetupStore.apiUrl + 'admin/submit/researchRequest/').then(researchRequests => {
      // Sort the submission by requestDate and return
      return researchRequests.data.sort((a, b) => {
        return new Date(b.requestDate) - new Date(a.requestDate)
      })
    }).catch(err => {
      console.error(err)
      Promise.reject(err)
    })
  },
  byId (id) {
    const appSetupStore = useAppSetupStore()
    return axios.get(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id)
  },
  create (researchRequest) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/', researchRequest)
  },
  update () {
    return {}
  },
  dataLibrarianApprove (id, approveData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id + '/approve', approveData)
  },
  dataLibrarianReject (id, rejectData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id + '/reject', rejectData)
  },
  // These two functions apply specificially to research requests
  // They are called by the PI to either accept or reject the request
  accept (acceptData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + 'finalize', acceptData)
  },
  reject (rejectData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + '/reject', rejectData)
  },
  // This is called by a member of the Oversight Committee to cast a vote
  vote (id, voteData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id + '/vote', voteData)
  },
  // This is called by a memeber of the UCMT to officially approve, reject, or cancel the request
  finalize (id, finalizeData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id + '/finalize', finalizeData)
  },
  notify (id, notifyData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id + '/notify', notifyData)
  },
  delete (id) {
    const appSetupStore = useAppSetupStore()
    return axios.delete(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id)
  },
  comment (id, commentData) {
    const appSetupStore = useAppSetupStore()
    return axios.post(appSetupStore.apiUrl + 'admin/submit/researchRequest/' + id + '/comment', commentData)
  }
}
