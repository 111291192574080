import axios from 'axios'
import datasetController from '../../controllers/datasets/index'
import { defineStore } from 'pinia'
import { useAuthenticationStore } from '@/store/authentication'
import { useAppSetupStore } from '@/store/appSetup'
export const useDatasetCatalogStore = defineStore('datasetCatalog', {
  state: () => ({
    isLoading: false,
    isError: false,
    currentDataset: Object.assign({}, datasetObject),
    all: [],
    
    datasets: [],
    selectedDatasets: [],
    adminNotes: null,
    
    datasetsLoading: true,
    datasetsError: false,
    
    fromCatalog: false,
    searchTerm: '',
    makeDownloadableSearchTerm: '',
    sensitiveSearchTerm: '',
    filters: {
      category: 'Show All',
      isInUdp: 'Show All',
      status: 'Show All',
      accessRestriction: 'Show All',
      isGeocoded: 'Show All',
      isDownloadable: 'Show All'
    },
    yearFilter: '',
    perPage: 15,
    selectedIndex: 0,
    versionDepth: 0,
    organizationsObj: {},
    filterColumns: ['category', 'udpName', 'shortDescription', 'keywords']
  }),
  getters: {
    // See if this can be simplified by concatenating keywords, udpNames, short descriptions, and categories
    // Maybe by sorting by the index of positions?
    filterDatasets: (state) => {
      // Start with original and an empty filtered datasets array
      let filteredDatasets = []
      let allGetters = {
        'allKeywords': 1,
        'allIds': 1,
        'allUdpNames': 1,
        'allShortDescriptions': 0.45,
        'allCategories': 0.55
      }

      // If there's no value for searchTerm, set filteredDatasets to the full array
      if (state.searchTerm === '') {
        filteredDatasets = state.datasets

        // Sort datasets by releaseDate (only if there's no searchTerm)
        filteredDatasets.sort(function (a, b) {
          if (a.releaseDate === null) return 1
          else if (b.releaseDate === null) return -1
          else return (a.releaseDate < b.releaseDate) ? 1 : ((b.releaseDate < a.releaseDate) ? -1 : 0)
        })
      } else { // Otherwise filter the full array
        // Split the search term on space, filter out empty string entries
        let searchTerms = state.searchTerm.split(' ').filter(word => word !== '')

        /* Returns all datasets where at least one term is matched, */
        /* however it favors results with multiple matches */
        let unorderedMap = new Map()

        // Loop through search terms
        searchTerms.forEach(term => {
          // For each search term, loop through and compare against each getter
          for (let getterKey in allGetters) {
            // For the getter, loop through each entry in the getter
            state[getterKey].forEach((entry, index) => {
              // If the entries match, add the index to the set
              let normEntry = entry ? entry.toLowerCase().trim() : ''
              let normTerm = term ? term.toLowerCase().trim() : ''
              let termIndex = normEntry.indexOf(normTerm)

              // If the term is found in the entry, add or increment the map
              if (termIndex > -1) {
                // See if the index already exists in the map
                if (unorderedMap.get(index)) {
                  // If so, increment it's value
                  unorderedMap.set(index, unorderedMap.get(index) + allGetters[getterKey])
                } else {
                  // Otherwise add to the map with an inital value of 1
                  unorderedMap.set(index, allGetters[getterKey])
                }
              }
            })
          }
        })
        // Reverse sort the map before pushing values to filteredDatasets
        let orderedMap = new Map([...unorderedMap.entries()].sort((a, b) => b[1] - a[1]))
        // Loop through the set to build new index to return
        orderedMap.forEach((val, key) => filteredDatasets.push(state.datasets[key]))
      }

      // Filter further using the dropdown values
      for (let key in state.filters) {
        if (state.filters[key] !== 'Show All') {
          let tempArray = []

          // Check to see if the filter column value matches the dataset column value
          filteredDatasets.forEach(ds => {
            // If there's a match, append to tempArray
            if (ds[key] === state.filters[key]) {
              tempArray.push(ds)
            } else if (key.substr(0, 2) === 'is') { // If filter starts with 'is', it's a Yes/No, boolean
              let boolTest = (state.filters[key] === 'Yes')
              if (boolTest === ds[key]) {
                tempArray.push(ds)
              }
            }
          })

          // Set filteredDatasets to the tempArray since it's now further filtered
          filteredDatasets = tempArray
        }
      }

      // Filter on year
      // If specificYears is populated, check it
      let tempArray = []
      if (state.yearFilter.length === 4) {
        filteredDatasets.forEach(ds => {
          if (ds.specificYears) {
            let years = ds.specificYears.replace(/\s/g, '').split(',')
            if (years.indexOf(state.yearFilter) > -1) {
              tempArray.push(ds)
            }
          } else if (ds.earliestDate && ds.latestDate) {
            let year = parseInt(state.yearFilter)
            let early = parseInt(ds.earliestDate.substr(0, 4))
            let later = parseInt(ds.latestDate.substr(0, 4))
            // If the year is greater than or equal to the earliest and
            // less than or equal to the latest, add to tempArray
            if (early <= year && year <= later) {
              tempArray.push(ds)
            }
          }
        })
        // Set filteredDatasets to the tempArray since it's now further filtered
        filteredDatasets = tempArray
      }

      // Return filteredDatasets as the datasets
      return filteredDatasets
    },
    makeDownloadableDatasets: (state) => {
      if (state.makeDownloadableSearchTerm === '') return state.datasets.filter(ds => ds.isDownloadable === false && ds.accessRestriction === 'Available to all members')
      else {
        let filteredReturn = []
        let allGetters = {
          'allKeywords': 1,
          'allIds': 1,
          'allUdpNames': 1,
          'allShortDescriptions': 0.45,
          'allCategories': 0.55
        }
  
        // Split the search term on space, filter out empty string entries
        let searchTerms = state.makeDownloadableSearchTerm.split(' ').filter(word => word !== '')
  
        /* Returns all datasets where at least one term is matched, */
        /* however it favors results with multiple matches */
        let unorderedMap = new Map()
  
        // Loop through search terms
        searchTerms.forEach(term => {
          // For each search term, loop through and compare against each getter
          for (let getterKey in allGetters) {
            // For the getter, loop through each entry in the getter
            state[getterKey].forEach((entry, index) => {
              // If the entries match, add the index to the set
              let normEntry = entry ? entry.toLowerCase().trim() : ''
              let normTerm = term ? term.toLowerCase().trim() : ''
              let termIndex = normEntry.indexOf(normTerm)
  
              // If the term is found in the entry, add or increment the map
              if (termIndex > -1) {
                // See if the index already exists in the map
                if (unorderedMap.get(index)) {
                  // If so, increment it's value
                  unorderedMap.set(index, unorderedMap.get(index) + allGetters[getterKey])
                } else {
                  // Otherwise add to the map with an inital value of 1
                  unorderedMap.set(index, allGetters[getterKey])
                }
              }
            })
          }
        })
  
        // Reverse sort the map before pushing values to filteredDatasets
        let orderedMap = new Map([...unorderedMap.entries()].sort((a, b) => b[1] - a[1]))
  
        // Loop through the set to build new index to return
        orderedMap.forEach((val, key) => filteredReturn.push(state.datasets[key]))
  
        // Return filteredReturn
        return filteredReturn.filter(ds => ds.isDownloadable === false && ds.accessRestriction === 'Available to all members')
      }
    },
    sensitiveDatasets: (state) => {
      if (state.sensitiveSearchTerm === '') return state.datasets.filter(ds => ds.accessRestriction === 'Authorization required')
      else {
        let filteredReturn = []
        let allGetters = {
          'allKeywords': 1,
          'allIds': 1,
          'allUdpNames': 1,
          'allShortDescriptions': 0.45,
          'allCategories': 0.55
        }
  
        // Split the search term on space, filter out empty string entries
        let searchTerms = state.sensitiveSearchTerm.split(' ').filter(word => word !== '')
  
        /* Returns all datasets where at least one term is matched, */
        /* however it favors results with multiple matches */
        let unorderedMap = new Map()
  
        // Loop through search terms
        searchTerms.forEach(term => {
          // For each search term, loop through and compare against each getter
          for (let getterKey in allGetters) {
            // For the getter, loop through each entry in the getter
            state[getterKey].forEach((entry, index) => {
              // If the entries match, add the index to the set
              let normEntry = entry ? entry.toLowerCase().trim() : ''
              let normTerm = term ? term.toLowerCase().trim() : ''
              let termIndex = normEntry.indexOf(normTerm)
  
              // If the term is found in the entry, add or increment the map
              if (termIndex > -1) {
                // See if the index already exists in the map
                if (unorderedMap.get(index)) {
                  // If so, increment it's value
                  unorderedMap.set(index, unorderedMap.get(index) + allGetters[getterKey])
                } else {
                  // Otherwise add to the map with an inital value of 1
                  unorderedMap.set(index, allGetters[getterKey])
                }
              }
            })
          }
        })
  
        // Reverse sort the map before pushing values to filteredDatasets
        let orderedMap = new Map([...unorderedMap.entries()].sort((a, b) => b[1] - a[1]))
  
        // Loop through the set to build new index to return
        orderedMap.forEach((val, key) => filteredReturn.push(state.datasets[key]))
  
        // Return filteredReturn
        return filteredReturn.filter(ds => ds.accessRestriction === 'Authorization required')
      }
    },
    allCategories: (state) => {
      let allCategories = []
      // Loop through all datasets
      for (let i = 0; i < state.datasets.length; i++) {
        allCategories.push(state.datasets[i].category)
      }
      // Return allCategories
      return allCategories
    },
    allUdpNames: (state) => {
      let allUdpNames = []
      // Loop through all datasets
      for (let i = 0; i < state.datasets.length; i++) {
        allUdpNames.push(state.datasets[i].udpName)
      }
      // Return allUdpNames
      return allUdpNames
    },
    allShortDescriptions: (state) => {
      let allShortDescriptions = []
      // Loop through all datasets
      for (let i = 0; i < state.datasets.length; i++) {
        allShortDescriptions.push(state.datasets[i].shortDescription)
      }
      // Return allShortDescriptions
      return allShortDescriptions
    },
    allKeywords: (state) => {
      let allKeywords = []
      // Loop through all datasets
      for (let i = 0; i < state.datasets.length; i++) {
        allKeywords.push(state.datasets[i].keywords)
      }
      // Return allKeywords
      return allKeywords
    },
    allIds: (state) => {
      let allIds = []
      // Loop through all datasets
      for (let i = 0; i < state.datasets.length; i++) {
        allIds.push(state.datasets[i].id)
      }
      // Return allIds
      return allIds
    },
    allStatuses: (state) => {
      let statusSet = new Set()
      // Loop through all datasets
      for (let i = 0; i < state.datasets.length; i++) {
        statusSet.add(state.datasets[i].status)
      }
      // Return all unique statuses
      return [...statusSet]
    },
    paginateDatasets: (state) => {
      let allPages = []
  
      // Loop through filterDatasets and break into 2d array with inner arrays
      // being the length of 'perPage'
      for (let i = 0; i < state.filterDatasets.length; i += state.perPage) {
        // Build 'page' array from slice of orignal array
        let tempArr = state.filterDatasets.slice(i, i + state.perPage)
        // Append to allPages
        allPages.push(tempArr)
      }
      return allPages
    },
    isFiltered: (state) => {
      let isFiltered = false
      // Loop through filters to check if it's set to Show All
      for (let key in state.filters) {
        if (state.filters[key] !== 'Show All') {
          isFiltered = true
        }
      }
      if (state.searchTerm !== '' || state.yearFilter.length === 4) {
        isFiltered = true
      }
      return isFiltered
    },

  },
  actions: {
    async loadDatasets () {
      this.isLoading = true
      try {
        let datasets = await datasetController.all()
        // Populate datasets array
        this.datasets = datasets
      } catch (error) {
        this.isError = true
        console.error(error)
      }
      this.isLoading = false;
    },
    async loadDatasetDetail (id) {
      const authenticationStore = useAuthenticationStore()
      // Check if user is logged in or not
      if (authenticationStore.isSignedIn) {
        try {
          // Get idToken and make API request for dataset
          let token = await authenticationStore.returnIdToken()
          let response = await datasetController.byIdAuthenticated(id, token)
          response.data.id = id
          this.currentDataset = response.data
        } catch (error) {
          console.error(error)
          this.isError = true
        }
      } else {
        try {
          let response = await datasetController.byIdUnauthenticated(id)
          response.data.id = id
          this.currentDataset = response.data
        } catch (error) {
          console.error(error)
          this.isError = true
        }
      }
    },
    async getOrganizations () {
      const appSetupStore = useAppSetupStore()
      try {
        //* @refactor: move direct api calls to controllers?
        // Make get request using inquiryFilter value
        let response = await axios.get(appSetupStore.apiUrl + 'organizations')
        // Set lookups
        this.organizationsObj = response.data

      } catch (error) {
        // Set to false to change back
        // this.isSubmitting = false
        console.log(error)
      }
    },


    
    // Mutations
    clearCurrentDataset() {
      this.currentDataset = Object.assign({}, datasetObject)
    },
    clearFilters(){
      this.searchTerm = ''
      this.yearFilter = ''
      this.filters = {
        category: 'Show All',
        isInUdp: 'Show All',
        status: 'Show All',
        accessRestriction: 'Show All',
        isGeocoded: 'Show All',
        isDownloadable: 'Show All'
      }
      this.selectedIndex = 0
    }
  },
})

const datasetObject = {
  'dataciteName': null,
  'originalDataCitation': null,
  'tabularResolution': null,
  'udpName': null,
  'status': null,
  'suggestedCitation': null,
  'isInGdb': null,
  'folderPathMulti': null,
  'shortDescription': null,
  'releaseDate': null,
  'released': null,
  'keywords': null,
  'updateFreq': null,
  'relatedDatasets': null,
  'doi': null,
  'accessRestriction': null,
  'earliestDate': null,
  'isGeocoded': null,
  'hasLatLong': null,
  'hasCensusGeocoded': null,
  'source': null,
  'paperCitation': null,
  'versionNo': null,
  'specificYears': null,
  'geoResolution': null,
  'geoResolutions': [],
  'isInUdp': null,
  'geoExtent': null,
  'isDownloadable': null,
  'category': null,
  'latestDate': null,
  'fullDescription': null,
  'id': null,
  'adminNotes': null,	
  'dataTables': [],	
  'dataDictTables': [],	
  'codebookTables': []
}
/*
const mutations = {
  // TODO: Namespacing!
  SET_ERROR (state, isError) {
    state.isError = isError
  },
}
*/
