

import Bowser from "bowser";
import axios from "axios";
import awsExports from "../../aws_exports";
import { defineStore } from 'pinia';
import { useAuthenticationStore  } from "@/store/authentication";
export const useAppSetupStore = defineStore("appSetup", {
  state: () => ({
    isFullWidth: false,
    isSignInModalOpen: false,
    modalType: null,
    isAppLoaded: false,
    isEdge: false,
    isMenuOpen: false,
    lookups: {},

    singleValueModal: {
      modalTitle: null,
      category: null,
      value: null,
    },
    lookupCategory: null,

    iconHeader: {
      ds: {
        i: "fa-upload",
        t: "Data Submission",
      },
      rr: {
        i: "fa-university",
        t: "Research Request",
      },
    },
    requestManagerTab: "general",
  }),
  getters: {
    apiUrl() {
      return this.appEnv === "development"
        ? awsExports.dev.apiUrl
        : awsExports.prod.apiUrl;
    },
    appEnv() {
      return process.env.VUE_APP_ENV.trim()
    },
  },
  actions: {
    async logger(data) {
      const authenticationStore = useAuthenticationStore()
      // Build object to log
      let logEntry = {
        "sessionId": authenticationStore.sessionId,
        "timestamp": Date.now(),
        "location": data.location,
        "action": data.action,
        "target": data.target,
        "user": data.user
          ? data.user
          : authenticationStore.userAttributes.email,
      };

      // Add environment attribute if action is Session Start
      if (logEntry.action === "Session Start") {
        // Get bowser object
        const b = Bowser.parse(window.navigator.userAgent);

        logEntry.environment = {
          "browserName": b.browser.name,
          "browserVersion": b.browser.version,
          "osName": b.os.name,
          "osVersion": b.os.version,
        };
      }

      //* @refactor: move direct api calls to controllers?
      // POST to the logging API
      try {
        await axios.post(this.apiUrl + "logging", logEntry);
      } catch (error) {
        console.error(error);
      }
    },
    async getLookups() {
      //* @refactor: move direct api calls to controllers?
      // Make request for lookups
      try {
        let response = await axios.get(this.apiUrl + "lookups");
        // Set lookups object in state
        this.lookups = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    promptSignInModal( path) {
        const authenticationStore = useAuthenticationStore()

        // Commit path to navigate to store
        authenticationStore.postAuthRoutePath = path
        // Set message and sign in modal
        authenticationStore.signInMessage = "You must sign in before viewing this page"
        
        // this.setStringStateProp({ "modalType": "signIn" });
        this.modalType = "signIn"
        // this.setBoolStateProp({ "isSignInModalOpen": true });
        this.isSignInModalOpen = true
      },
    // Mutations
    //* @fix required: these are just abstractions to change state properties
    //*                change these to simple pinia state changes like this.isSignInModalOpen = true
    setBoolStateProp(obj) {
      // Test that property is a boolean
      for (let key in obj) {
        // Skip prototype properties
        if (!Object.hasOwn(obj, key)) continue;

        // If key is a boolean and the state has the key, then update it
        if (typeof obj[key] === "boolean" && Object.hasOwn(this, key)) {
            this[key] = obj[key];
        }
      }
    },
    setStringStateProp(obj) {
      // Test that property is a boolean
      for (let key in obj) {
        // Skip prototype properties
        if (!Object.hasOwn(obj, key)) continue;

        // If key is a string and the state has the key, then update it
        if (typeof obj[key] === "string" && Object.hasOwn(this, key)) {
            this[key] = obj[key];
        }
      }
    },
    clearSingleValModal() {
      this.singleValueModal = {
        modalTitle: null,
        category: null,
        value: null,
      };
    },
  },
});
