import Router from 'vue-router'
import { canAccessNextPage } from '@/lib/udpUtils'
import { useAuthenticationStore } from '@/store/authentication'
import { useResearchRequestStore } from '@/store/researchRequest'
import { useAppSetupStore } from '@/store/appSetup'

/* Home */
const Home = () => import('@/pages')

/* FAQ */
const Faq = () => import('@/pages/faq')

/* Account */
const Registration = () => import('@/pages/register')
const Verification = () => import('@/pages/register/verify')
const Confirmation = () => import('@/pages/register/confirm')
const UserProfile = () => import('@/pages/userProfile')

/* Dataset Catalog */
const DatasetCatalog = () => import('@/pages/datasetCatalog')
const DatasetDetail = () => import('@/pages/datasetCatalog/byId')

/* Contact Us */
const SubmitData = () => import('@/pages/contactUs/submitData')
const GeneralInquiries = () => import('@/pages/contactUs/generalInquiries')
const ResearchRequestLanding = () => import('@/pages/contactUs/researchRequest')
const ResearchRequest = () => import('@/pages/contactUs/researchRequest/new')
const DataSubmissionForm = () => import('@/pages/contactUs/submitData/new')

/*******************/
/**  Admin Tools  **/
/*******************/

/* Asset Manager */
const AssetManager = () => import('@/pages/admin/assetManager')

/* Oversight Committee Dashboard */
const OversightCommitteeDashboard = () => import('@/pages/admin/oversightCommittee')
const OCResearchRequestDetail = () => import('@/pages/admin/oversightCommittee/research')

/* Catalog Entry Manager */
const DatasetManager = () => import('@/pages/admin/datasetManager')
const ManageDatasets = () => import('@/pages/admin/datasetManager/manageDatasets')

const EditDatasetDetail = () => import('@/pages/admin/datasetManager/manageDatasets/edit')
const DatasetUpdates = () => import('@/pages/admin/datasetManager/datasetUpdates')
const ManageLookups = () => import('@/pages/admin/datasetManager/manageLookups')
const ManageOrganizations = () => import('@/pages/admin/datasetManager/manageOrgs')

/* Request Manager */
const RequestManager = () => import('@/pages/admin/requestManager')
const ResearchRequestDetail = () => import('@/pages/admin/requestManager/research')
const GeneralInquiryDetail = () => import('@/pages/admin/requestManager/general')
const DataSubmissionDetail = () => import('@/pages/admin/requestManager/dataSubmission')

/* SQL Query */
const SqlQuery = () => import('@/pages/admin/sqlQuery')

/* User Manager */
const UserManager = () => import('@/pages/admin/userManager')

/* User Dashboard */
const DevTools = () => import('@/pages/admin/devTools')

const router = new Router({
  routes: [
    // This route is a kind of a hack. When a user signs out, I wanted to
    // see if they should be able to have access to the page they were on.
    // There's already a beforeEnter => verifyAuth function, but I can't
    // navigate to the same route that I'm on. So this one is a dummy route
    // that just navigates back to the page it came from and let's the
    // existing function handle it. Also gave it an obscure path so people
    // didn't stumble onto it.
    {
      path: '/signOutjj2j8f39imgn39sd7fm590x1', // Rather than obscuring it, maybe check the to.name to see if it's null and skip?
      name: 'signOut',
      component: null,
      beforeEnter: (to, from, next) => {
        const authenticationStore = useAuthenticationStore()
        // Start with a truthy to AND with and see if it comes out true or false in the end
        let verified = true

        // Check user roles to see if they can remain on the current page
        if (from.meta.roles) {
          // If user is logged in, loop through arrRoles to verify user should have access
          from.meta.roles.forEach(role => {
            // Compare role with one in auth state object
            verified = authenticationStore.userAttributes[role] && verified
          })
        }
        // If still verified, go to next()
        if (verified) {
          next(from.path)
        } else {
          next({name: 'Home'})
        }
      }
    },
    // Redirect all unknown paths to the Home component
    { path: '*', redirect: '/' },
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/faq',
      name: 'Faq',
      component: Faq,
      props: true
    },
    {
      path: '/register',
      name: 'Registration',
      component: Registration,
      beforeEnter: (to, from, next) => isSignedInPrevent(to, from, next)
    },
    {
      path: '/register/verify',
      name: 'Verification',
      component: Verification,
      meta: {
        roles: ['isSignedIn']
      },
      beforeEnter: (to, from, next) => {
        const authenticationStore = useAuthenticationStore()
        if (authenticationStore.isSignedIn && authenticationStore.userAttributes.email_verified) {
          // Prevent access to this page if user is signed in and verified
          next(false)
        } else {
          // Otherwise allow access
          next()
        }
      }
    },
    {
      path: '/register/confirm',
      name: 'Confirmation',
      component: Confirmation,
      beforeEnter: (to, from, next) => isSignedInPrevent(to, from, next)
    },
    /* 
      TODO: Investigate what happens here since it's not documented.
      My guess is that the verification code is checked then it shows a modal for sign in?
    */
    {
      path: '/confirm/:verificationCode',
      name: 'Confirm',
      component: Home
    },
    {
      path: '/datasetCatalog',
      name: 'datasetCatalog',
      component: DatasetCatalog
    },
    {
      path: '/datasetCatalog/:id',
      name: 'DatasetDetail',
      component: DatasetDetail,
      props: true // Passes the id value from the path to the component prop named id
    },
    {
      path: '/contactUs/submitData',
      name: 'DataSubmissionHome',
      component: SubmitData
    },
    {
      path: '/contactUs/submitData/new',
      name: 'DataSubmissionFormNew',
      component: DataSubmissionForm,
      meta: {
        roles: ['isSignedIn']
      },
      props: { mode: 'new' },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next, true)
    },
    {
      path: '/contactUs/researchRequest',
      name: 'ResearchRequestLanding',
      component: ResearchRequestLanding
    },
    {
      path: '/contactUs/researchRequest/new',
      name: 'ResearchRequestNew',
      component: ResearchRequest,
      props: { mode: 'new' },
      meta: {
        roles: ['isSignedIn']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next, true)
    },
    {
      path: '/contactUs/researchRequest/edit',
      name: 'ResearchRequestEdit',
      component: ResearchRequest,
      props: { mode: 'edit' }
    },
    {
      path: '/contactUs/generalInquiries',
      name: 'GeneralInquiries',
      component: GeneralInquiries
    },
    {
      path: '/contactUs/researchRequest/set/:id/:guid',
      redirect: to => {
        //* @refactor:
        //  Rather than do this, pass id and guid as props into component
        //  Then in component commit back to store rather than this way
        const researchRequestStore = useResearchRequestStore()
        // Set id and guid using state mutation then route to accept submission
        researchRequestStore.request.id = to.params.id 
        researchRequestStore.request.piGuid = to.params.guid 
        return { name: 'ResearchRequestEdit' }
      }
    },
    {
      path: '/userProfile',
      name: 'userProfile',
      component: UserProfile,
      meta: {
        roles: ['isSignedIn']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next, true)
    },

    /* Admin pages */

    {
      path: '/admin/assetManager',
      name: 'Asset Manager',
      component: AssetManager,
      meta: {
        roles: ['isAdmin', 'isDataManager']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },

    {
      path: '/admin/oversightCommittee',
      name: 'Oversight Committee Dashboard',
      component: OversightCommitteeDashboard,
      meta: {
        roles: ['isOversightCommittee']
      },
      beforeEnter: (to, from, next) => {
        const authenticationStore = useAuthenticationStore()
        const appSetupStore = useAppSetupStore()

        if (!authenticationStore.isSignedIn) appSetupStore.promptSignInModal(to.path)
        else {
          if (authenticationStore.isSignedIn 
            && (authenticationStore.userAttributes.isUDPCoreMgmt || authenticationStore.userAttributes.isOversightCommittee)) {
            next()
          } else {
            if (!from.name) next('/')
            else next(false)
          }
        }
      }
    },
    {
      path: '/admin/oversightCommittee/research/:id',
      name: 'Oversight Committee Research Request',
      component: OCResearchRequestDetail,
      meta: {
        roles: ['isOversightCommittee']
      },
      props: true,
      beforeEnter: (to, from, next) => {
        const authenticationStore = useAuthenticationStore()
        const appSetupStore = useAppSetupStore()



        
        if (!authenticationStore.isSignedIn) appSetupStore.promptSignInModal(to.path)
        else {
          if (authenticationStore.userAttributes.isUDPCoreMgmt 
            || authenticationStore.userAttributes.isOversightCommittee 
            || authenticationStore.userAttributes.isAdmin) {
            next()
          } else {
            if (!from.name) next('/')
            else next(false)
          }
        }
      }
    },
    {
      path: '/admin/requestManager',
      name: 'RequestManager',
      component: RequestManager,
      meta: {
        roles: ['isAdmin']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },
    {
      path: '/admin/requestManager/research/:id',
      name: 'ResearchRequestDetail',
      component: ResearchRequestDetail,
      meta: {
        roles: ['isAdmin']
      },
      props: true,
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },
    {
      path: '/admin/requestManager/dataSubmission/:id',
      name: 'DataSubmissionDetail',
      component: DataSubmissionDetail,
      meta: {
        roles: ['isAdmin']
      },
      props: true,
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },
    {
      path: '/admin/requestManager/general/:id',
      name: 'GeneralInquiryDetail',
      component: GeneralInquiryDetail,
      meta: {
        roles: ['isAdmin']
      },
      props: true,
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },
    {
      path: '/admin/datasetManager',
      name: 'datasetManager',
      component: DatasetManager,
      redirect: {
        name: 'manageDatasets'
      },
      children: [
        {
          path: '/admin/datasetManager/manageDatasets',
          name: 'manageDatasets',
          component: ManageDatasets,
          meta: {
            roles: ['isAdmin', 'isDataManager']
          },
          beforeEnter: (to, from, next) => verifyAuth(to, from, next)
        },
        {
          path: '/admin/datasetManager/datasetUpdates',
          name: 'DatasetUpdates',
          component: DatasetUpdates,
          meta: {
            roles: ['isAdmin', 'isDataManager']
          },
          beforeEnter: (to, from, next) => verifyAuth(to, from, next)
        },
        {
          path: '/admin/datasetManager/manageDatasets/edit/:id',
          name: 'mdEditDataset',
          component: EditDatasetDetail,
          meta: {
            roles: ['isAdmin', 'isDataManager'],
            mode: 'edit'
          },
          beforeEnter: (to, from, next) => verifyAuth(to, from, next)
        },
        {
          path: '/admin/datasetManager/manageDatasets/add',
          name: 'mdAddDataset',
          component: EditDatasetDetail,
          meta: {
            roles: ['isAdmin', 'isDataManager'],
            mode: 'add'
          },
          props: true,
          beforeEnter: (to, from, next) => verifyAuth(to, from, next)
        },
        {
          path: '/admin/datasetManager/manageLookups',
          name: 'ManageLookups',
          component: ManageLookups,
          meta: {
            roles: ['isAdmin', 'isDataManager']
          },
          beforeEnter: (to, from, next) => verifyAuth(to, from, next)
        },
        {
          path: '/admin/datasetManager/manageOrgs',
          name: 'ManageOrganizations',
          component: ManageOrganizations,
          meta: {
            roles: ['isAdmin', 'isDataManager']
          },
          beforeEnter: (to, from, next) => verifyAuth(to, from, next)
        }
      ]
    },
    {
      path: '/admin/sqlQuery',
      name: 'SqlQuery',
      component: SqlQuery,
      meta: {
        roles: ['isAdmin', 'isDataManager']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },
    {
      path: '/admin/userManager',
      name: 'UserManager',
      component: UserManager,
      meta: {
        roles: ['isAdmin']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    },
    {
      path: '/admin/devTools',
      name: 'DevTools',
      component: DevTools,
      meta: {
        roles: ['isDeveloper', 'isAdmin']
      },
      beforeEnter: (to, from, next) => verifyAuth(to, from, next)
    }
  ],
  linkExactActiveClass: 'is-active',
})

/* This is the first function that will execute after main.js loads */
/* This will run after each navigation, but the if block will */
router.beforeEach(async (to, from, next) => {
  if (Window.isSignedIn) {
    // Set user attributes
    const authenticationStore = await useAuthenticationStore()
    authenticationStore.setSignedInUser(Window.currentUser.attributes)
    // Clear globals so this only runs once
    Window.isSignedIn = undefined
    Window.currentUser = undefined
  }

  next()
})

export default router

async function verifyAuth (to, from, next, isSimple) {
  const authenticationStore = await useAuthenticationStore()
  const appSetupStore = await useAppSetupStore()

  // If simple check, only determine if user is signed in
  let isSignedIn = authenticationStore.isSignedIn
  let userCanAccessNextPage = canAccessNextPage(to, authenticationStore.userAttributes)

  let verified

  if (isSimple) verified = isSignedIn
  else verified = isSignedIn && userCanAccessNextPage

  // If verified, let pass
  if (verified) next()
  // If not verifed, but signed in - prevent
  else if (!verified && isSignedIn) next(false)
  // If not verified not signed in - prompt to sign in
  else {
    appSetupStore.promptSignInModal(to.path)
    next(false)
  }
}

// Opposite of above function. Only used on registration pages to prevent
// athenticated users from registering another new account.
function isSignedInPrevent (to, from, next) {
  const authenticationStore = useAuthenticationStore()

  // Checked to see if user is logged in beore moving forward
  if (authenticationStore.isSignedIn) next(false)
  else next()
}
