import axios from 'axios'

// TODO - REMOVE THE STORE REFERENCE; MOVE API_URL TO ENVIRONMENT VARIABLE
import { useAppSetupStore } from '@/store/appSetup'

const NEW_URL = `https://api.kinderudp.org${process.env.VUE_APP_ENV.trim() === 'development' ? '/test' : ''}`

export default {
  all () {
    const appSetupStore = useAppSetupStore()
    return axios.get(appSetupStore.apiUrl + 'datasets').then(response => {
      // Sort datasets alphabetically
      response.data.sort(function (a, b) { return (a.udpName > b.udpName) ? 1 : ((b.udpName > a.udpName) ? -1 : 0) })
      return response.data
    }).catch(error => {
      return Promise.reject(error)
    })
  },
  byIdUnauthenticated (id) {
    return axios.get(`https://4jnoun82yd.execute-api.us-west-2.amazonaws.com/datasets/${id}/public`)
  },
  byIdAuthenticated (id, token) {
    return axios.get(`${NEW_URL}/catalog/${id}`, {
      headers: {
        Authorization: token
      }
    })
  },
  byId (id) {
    const appSetupStore = useAppSetupStore()
    return axios.get(appSetupStore.apiUrl + 'datasets/' + id + '/vrde')
  },
  create () {
    return {}
  },
  update () {
    return {}
  },
  delete () {
    return {}
  }
}
