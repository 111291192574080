export default {
  dev: {
    Auth: {
      identityPoolId: 'us-west-2:ea17af47-7385-41f6-b909-5192480d9f7f', // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      region: 'us-west-2', // REQUIRED - Amazon Cognito Region
      identityPoolRegion: 'us-west-2', // Required only if it's different from Amazon Cognito Region
      userPoolId: 'us-west-2_UvDhr1Pkx', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '3fck555bi96hrk75vr4et0f82c' // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    },
    apiUrl: 'https://5l498ma30i.execute-api.us-east-1.amazonaws.com/dev/',
    buckets: {
      userDocumentation: 'udp-user-documentation-dev'
    }
  },
  prod: {
    Auth: {
      identityPoolId: 'us-east-1:12261ddd-e150-4136-91c6-bce2d7fe6569', // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      region: 'us-east-1', // REQUIRED - Amazon Cognito Region
      identityPoolRegion: 'us-east-1', // Required only if it's different from Amazon Cognito Region
      userPoolId: 'us-east-1_OGW2tSVbv', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '3hs5f7190pekqpi8hv5i6uvv04' // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    },
    apiUrl: 'https://lg4tooqkr1.execute-api.us-east-1.amazonaws.com/prod/',
    buckets: {
      userDocumentation: 'udp-user-documentation'
    }
  }
}
